import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/DashboardPage.vue')
  }
  ,
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/UserManagement.vue')
  },
  {
    path: '/settlements',
    name: 'Settlements',
    component: () => import('@/views/SettlementManagement.vue')
  },
  {
    path: '/signed-users',
    name: 'SignedUsers',
    component: () => import('@/views/SignedUsers.vue')
  },
  {
    path: '/paid-users',
    name: 'PaidUsers',
    component: () => import('@/views/PaidUsers.vue')
  },
  {
    path: '/verified-users',
    name: 'VerifiedUsers',
    component: () => import('@/views/VerifiedUsers.vue')
  },
  {
    path: '/total-users',
    name: 'TotalUsers',
    component: () => import('@/views/TotalUsers.vue')
  },
  {
    path: '/agents',
    name: 'Agents',
    component: () => import('@/views/AgentManagement.vue')
  },
  {
    path: '/agent-details',
    name: 'AgentDetails',
    component: () => import('@/views/AgentsPage.vue')
  },
  {
    path: '/agent-settlements',
    name: 'AgentSettlements',
    component: () => import('@/views/AgentsSettlement.vue')
  }
  ,
  {
    path: '/user-settlement',
    name: 'UserSettlements',
    component: () => import('@/views/UsersSettlement.vue')
  },
  {
    path: '/unassigned-org',
    name: 'UnassignedOrganizaton',
    component: () => import('@/views/UnassignedOrganizaton.vue')
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: () => import('@/views/AdminPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
